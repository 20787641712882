import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Box, Container, CssBaseline, Paper, Grid, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MessageDisplay from './components/messageDisplay';
import MessageForm from './components/messageForm';
import { supabase } from './util/supabaseClient';
import SignInPopup from './components/signUp';

const App = () => {
  // Local testing Endpoint
  // const apiURL = 'http://localhost:3000/api/create-thread-tenant';
  // Live endpoint
  const apiURL = 'https://irserver.onrender.com/api/create-thread-tenant';
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [showSignIn, setShowSignIn] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [user, setUser] = useState(null);
  const [showPanel, setShowPanel] = useState(false);  // New state variable for panel visibility

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user || null);
    };

    fetchSession();

    const { data: listener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
      if (session?.user) {
        setShowSignIn(false); // Hide the sign-in popup if the user is logged in
      }
    });

    

    // Clean up the subscription
    return () => {
      listener.subscription.unsubscribe();
    };
  }, []);

  // Function to toggle the instruction panel
  const togglePanel = () => {
    setShowPanel(!showPanel);
  };

  const handleAuthClick = () => {
    setShowSignIn(true);
  };

  const handleSendMessage = async (messageContent) => {
    setIsSending(true);
    setMessages(prevMessages => [...prevMessages, { role: 'user', content: messageContent }]);

    try {
      const response = await axios.post(apiURL, { message: messageContent, threadId });
      const { threadId: newThreadId, response: assistantResponse } = response.data;

      if (!threadId || threadId !== newThreadId) {
        setThreadId(newThreadId);
      }

      setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: assistantResponse }]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <CssBaseline />
      {showSignIn && <SignInPopup show={showSignIn} onClose={() => setShowSignIn(false)} />}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={togglePanel}
            sx={{ mr: 2, display: { sm: 'none' } }}  // Show only in xs screens
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Source: BC Tenancy Act
          </Typography>
        </Toolbar>
      </AppBar>
      {/* {showSignIn && <SignInPopup show={showSignIn} onClose={() => setShowSignIn(false)} />}
      <AppBar position="static">
        <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Source: BC Tenancy Act
          </Typography>
          {/* {user ? (
            <Button color="inherit" onClick={() => {
              supabase.auth.signOut();
              setUser(null);
            }}>Logout {user.email}</Button>
          ) : (
            <Button color="inherit" onClick={() => setShowSignIn(true)}>Login / Sign Up</Button>
          )} */}
      {/*  </Toolbar>
      </AppBar> */} 
      <Grid container component="main" sx={{ height: 'calc(100vh - 64px)' }}>
        <Grid item xs={false} sm={4} md={3} sx={{ display: { xs: showPanel ? 'block' : 'none', sm: 'block' }, borderRight: '1px solid rgba(0, 0, 0, 0.12)', height: '100%', overflow: 'auto' }}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h5">Instructions</Typography>
            <Typography>
              <br/>
              This system answers questions based on the BC Tenancy Act.
              <br/>
              <br/>
              <bold>How does this work?</bold>
              <br/>
              Simply ask questions about evictions, forms, and anything related to tenants.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Paper sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              <MessageDisplay messages={messages} />
            </Box>
            <Box sx={{ padding: { xs: '0', sm: '16px' }, width: '100%' }}>
              <MessageForm 
                threadId={threadId} 
                onSendMessage={handleSendMessage} 
                isSending={isSending} 
                user={user}
                // setShowSignIn={setShowSignIn}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default App;
