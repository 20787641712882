import React, { useEffect, useRef } from 'react';
import { List, ListItem, Paper, Typography, Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const MessageDisplay = ({ messages }) => {
  const bottomListRef = useRef(null);

  useEffect(() => {
    bottomListRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <Paper style={{ maxHeight: '75vh', overflow: 'auto', position: 'relative' }}>
      <List>
        {messages.map((message, index) => {
          const isUserMessage = message.role === 'user';

          return (
            <React.Fragment key={index}>
              <ListItem style={{
                justifyContent: isUserMessage ? 'flex-end' : 'flex-start',
                flexDirection: 'column', // Stack the title and the message content vertically
                alignItems: isUserMessage ? 'flex-end' : 'flex-start',
              }}>
                {/* Conditional rendering for title based on the sender */}
                {isUserMessage && (
                  <Typography variant="caption" component="div" align="right" color="textSecondary" style={{ fontSize: '0.9rem' }}>
                    You
                  </Typography>
                )}

                <Box bgcolor={isUserMessage ? 'primary.main' : 'grey.100'}
                     color={isUserMessage ? 'primary.contrastText' : 'text.primary'}
                     p={1} borderRadius={2} maxWidth="75%">
                  <ReactMarkdown
                    children={message.content}
                    components={{
                        // Define custom styling for the 'p' element
                        p: ({ node, ...props }) => (
                          <Typography
                            {...props}
                            variant="body1"
                            style={{
                              fontSize: '1rem',
                              marginBottom: '1em', // Add bottom margin to each paragraph for spacing
                            }}
                          />
                        ),
                    }}
                  />
                </Box>

                {!isUserMessage && (
                  <Typography variant="caption" component="div" align="left" color="textSecondary" style={{ fontSize: '0.9rem' }}>
                    Tenancy Ai
                  </Typography>
                )}
              </ListItem>
            </React.Fragment>
          );
        })}
        <div ref={bottomListRef} />
      </List>
    </Paper>
  );
};

export default MessageDisplay;
